import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import setHTML from '@codedrop/utils/esm/utils';
import SvgGraphic from '-!svg-react-loader!./images/image-cluster.svg';
import Modal from '@codedrop/modal/esm/modal';
import Form from '@codedrop/form/esm/form-dynamic';

const FeatureArticle = ({ featureArticle }) => {
  const [modalState, setModalState] = useState(false);

  return (
    <Layout className="background--firework section--no-spacing section--bottom-l">
      <div className="feature-article ">
        <div className="feature-article__graphic-wrapper">
          <figure className="feature-article__graphic">
            <SvgGraphic />
          </figure>
        </div>
        <div className="text--center">
          <Heading tag="h1" headingClass="heading--special heading--xxxxl">
            {setHTML(featureArticle.heading)}
          </Heading>
          <p className="text--lede">{setHTML(featureArticle.ledeText)}</p>
          <Modal
            buttonText={featureArticle.buttonText}
            title={featureArticle.modalContent.modalTitle}
            modalState={modalState}
            setModalState={setModalState}
            id={`cta-modal-${featureArticle.modalContent.formName}`}
          >
            <Form
              modifierClass={featureArticle.modalContent.formModifierClass}
              formName={featureArticle.modalContent.formName}
              formSubmitText={featureArticle.modalContent.formSubmitText}
              formFields={featureArticle.modalContent.formFields}
              formSuccess={featureArticle.modalContent.formSuccess}
              formFail={featureArticle.modalContent.formFail}
              callBack={() => {
                setModalState(false);
              }}
            />
            <div className="text--xxs">
              {setHTML(featureArticle.modalContent.modalContent)}
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

FeatureArticle.displayName = 'FeatureArticle';
FeatureArticle.propTypes = {
  featureArticle: PropTypes.object.isRequired,
};

export default FeatureArticle;
