import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import setHTML from '@codedrop/utils/esm/utils';
import HowItWorksPanel from './how-it-works-panel';
import HowItWorksAnimation from './how-it-works-animation';

const HowItWorks = ({ howItWorks }) => {
  const [animationStage, setAnimationStage] = useState('0');
  const [animationIsAbove, setAnimationIsAbove] = useState(false);
  function handleIntersectionChange(animationIndex, isAbove) {
    setAnimationIsAbove(isAbove);
    setAnimationStage(`${animationIndex}`);
  }

  return (
    <Layout className="section--top-l how-it-works__section background--firework-left-right">
      <div className="how-it-works">
        <Heading tag="h2" headingClass="heading--s heading--context">
          {setHTML(howItWorks.heading)}
        </Heading>
        <Heading tag="h3" headingClass="heading--xxl heading--special">
          {setHTML(howItWorks.subHeading)}
        </Heading>
        <div className="how-it-works__grid">
          <div className="how-it-works__column">
            {howItWorks.panels.map((panel, index) => (
              <Fragment key={index}>
                <HowItWorksAnimation
                  animid={index}
                  currentAnimationIsAbove={false}
                  currentAnimationStage={`${index}`}
                  isMobileOnly={true}
                />
                <HowItWorksPanel
                  index={`${index}`}
                  onIntersectionChange={handleIntersectionChange}
                >
                  <div className="how-it-works__panel__counter">
                    {index + 1}
                  </div>
                  <Heading tag="h3" headingClass="heading--l">
                    {setHTML(panel.heading)}
                  </Heading>
                  {panel.content.map((item, index) => (
                    <span key={index}>{setHTML(item)}</span>
                  ))}
                </HowItWorksPanel>
              </Fragment>
            ))}
          </div>
          <div className="how-it-works__animation">
            <HowItWorksAnimation
              animid={'desktop'}
              currentAnimationIsAbove={animationIsAbove}
              currentAnimationStage={animationStage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

HowItWorks.displayName = 'HowItWorks';
HowItWorks.propTypes = {
  howItWorks: PropTypes.object.isRequired,
};

export default HowItWorks;
