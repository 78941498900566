import React from 'react';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import FeatureArticle from '../components/feature-article/feature-article';
import Benefits from '../components/benefits/benefits';
import Cta from '@codedrop/cta/esm/cta';
import Footer from '@codedrop/footer/esm/footer';
import Customers from '@codedrop/customers/esm/customers';
import index from '../data/index.json';
import data from '../data/skpr.json';
import HowItWorks from '../components/how-it-works/how-it-works';
import Developers from '../components/developers/developers';

const {
  seo,
  header,
  footer,
  featureArticle,
  howItWorks,
  benefits,
  developers,
  cta,
  customers,
  ctaForm
} = data;
const { site } = index;
featureArticle.modalContent = ctaForm

const IndexPage = () => (
  <div>
    <SEO title={site.title} seo={seo} />
    <Header header={header} />
    <main>
      <FeatureArticle featureArticle={featureArticle} />
      <div className="divider" />
      <Customers customers={customers} />
      <div className="divider" />
      <HowItWorks howItWorks={howItWorks} />
      <Benefits benefits={benefits} />
      <div className="divider" />
      <Developers developers={developers} />
      <Cta cta={cta} />
    </main>
    <Footer footer={footer} />
  </div>
);

export default IndexPage;
