import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import setHTML from '@codedrop/utils/esm/utils';
import SvgGraphic from '-!svg-react-loader!./images/console.svg';

const Developers = ({ developers }) => {
  return (
    <Layout className="background--firework section--top-l section--bottom-l">
      <div className="developers grid">
        <div className="grid__col grid--6-col">
          <Heading tag="h2" headingClass="heading--special heading--xxl">
            {setHTML(developers.title)}
          </Heading>
          <p>{developers.description}</p>
          <ul className="list--ticks">
            {developers.list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <a className="button" href={developers.button.link}>
            {developers.button.text}
          </a>
        </div>
        <div className="grid__col grid--6-col developers__graphic-column">
          <figure className="developers__graphic">
            <SvgGraphic />
          </figure>
        </div>
      </div>
    </Layout>
  );
};

Developers.displayName = 'Developers';
Developers.propTypes = {
  developers: PropTypes.object.isRequired,
};

export default Developers;
