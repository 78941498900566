import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const HowItWorksPanel = ({ children, index, onIntersectionChange }) => {
  const panelRef = useRef(null);

  useEffect(() => {
    const intersectionPanel = panelRef.current;

    const intersectionCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.75) {
          const isAbove = entry.boundingClientRect.y < entry.rootBounds.y;
          onIntersectionChange(index, isAbove);
        }
      });
    };

    const observer = new IntersectionObserver(intersectionCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.75,
    });
    observer.observe(intersectionPanel);

    return () => {
      observer.unobserve(intersectionPanel);
    };
  }, [index, onIntersectionChange]);

  return (
    <div
      ref={panelRef}
      className="how-it-works__panel"
      data-panel-index={index}
    >
      {children}
    </div>
  );
};

HowItWorksPanel.displayName = 'HowItWorksPanel';
HowItWorksPanel.propTypes = {
  index: PropTypes.string.isRequired,
  onIntersectionChange: PropTypes.func.isRequired,
};

export default HowItWorksPanel;
