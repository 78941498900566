import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import setHTML from '@codedrop/utils/esm/utils';

const Benefits = ({ benefits }) => {
  return (
    <Layout className="section--top-l">
      <div className="benefits">
        <Heading tag="h2" headingClass="heading--s heading--context">
          {benefits.title}
        </Heading>
        <Heading tag="h3" headingClass="heading--xxl heading--special">
          {benefits.subTitle}
        </Heading>
        <div className="grid">
          {benefits.items.map((item, index) => {
            return (
              <div className="grid__col grid--4-col" key={`benefits-${index}`}>
                <span className="heading--m text--secondary">
                  {item.secondaryHeading}
                </span>
                <h3 className="heading--l">{setHTML(item.heading)}</h3>
                <p>{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

Benefits.displayName = 'Benefits';
Benefits.propTypes = {
  benefits: PropTypes.object.isRequired,
};

export default Benefits;
